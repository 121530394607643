<!--PAGE-TOP-->
<div class="i-page-top">
  <div class="container">
    <!--Menu-->
    <div class="i-menu row align-items-center pt-3 pb-2">
      <div class="navbar-brand col-6 col-sm-8 col-md-6 col-lg-4 mr-auto py-2 px-2 text-center text-sm-left">
        <a href="#" routerLink="/home"
          ><img src="assets/img/logo_cinza.svg" class="img-fluid my-auto" alt="Private"
        /></a>
      </div>
      <div class="col-6 col-sm-4 col-md-6 col-lg-8 ml-auto py-2">
        <app-menu></app-menu>
      </div>
    </div>
    <div *ngIf="countFavorites$ | async as countFavorites" class="text-right">
      <a
        id="countFavorites"
        routerLink="/imoveis/favoritos"
        class="i-text"
        data-toggle="tooltip"
        [title]="'VOCÊ TEM ' + countFavorites + (' favorito' | plural: countFavorites | uppercase)"
        [attr.aria-label]="'VOCÊ TEM ' + countFavorites + (' favorito' | plural: countFavorites | uppercase)"
        data-placement="bottom">
        {{ countFavorites }}
        <i class="iconify" data-icon="mdi-heart"></i>
      </a>
    </div>
    <app-form-busca
      [(search)]="search"
      (changeSearch)="onChangeRoute($event)"
      [enableCount]="true"
      [enableSticky]="true"></app-form-busca>
  </div>
</div>
<!--PAGE-CONTENT-->
<div class="i-page-content" (scroll)="onWindowScroll($event)">
  <!--Results-Section-->
  <div id="results" class="i-results container">
    <div class="row align-items-center">
      <!-- Novo título definido pela MestreSEO -->
      <div class="i-title col-12 order-3 order-lg-2 text-center pt-3 pt-lg-4">
        IMÓVEIS PARA
        {{ search?.status === '0' ? 'COMPRAR' : 'ALUGAR' }}
        <!-- <ng-container *ngIf="search?.bairros?.length === 1; else tituloCidade">
          EM {{ bairroSelecionado | uppercase }}
        </ng-container> -->
        <ng-template #tituloCidade>
          <ng-container *ngIf="search?.cidadeNome?.length > 0"> EM {{ search?.cidadeNome | uppercase }} </ng-container>
        </ng-template>
        <!-- <div [innerHTML]="empreendimentoSelecionado"></div> -->
      </div>
    </div>
    <div class="row align-items-center">
      <div class="i-counter i-text col-6 col-sm-7 col-lg-4 order-1 text-left mr-auto py-4">
        {{ resultPage.items?.length ?? 0 }} / {{ resultPage.total }} RESULTADOS ENCONTRADOS
      </div>
      <div class="i-text col-6 col-sm-5 col-lg-4 d-flex order-2 order-lg-3 text-right py-3 py-lg-4">
        <div class="d-flex flex-column flex-md-row ml-auto">
          <div class="d-flex flex-row ml-auto">
            <button class="i-view pr-3 pr-lg-2 p-2" (click)="onChangeViewType(BoxType.Grid)">
              <picture>
                <source
                  type="image/webp"
                  [attr.srcset]="
                    search.boxType === BoxType.Grid ? 'assets/img/btn_grid_on.webp' : 'assets/img/btn_grid_off.webp'
                  " />
                <img
                  [src]="search.boxType === BoxType.Grid ? 'assets/img/btn_grid_on.png' : 'assets/img/btn_grid_off.png'"
                  style="width: 17px; height: 11px"
                  alt="Grid/List" />
              </picture>
            </button>
            <button class="i-view p-2" (click)="onChangeViewType(BoxType.List)">
              <picture>
                <source
                  type="image/webp"
                  [attr.srcset]="
                    search.boxType !== BoxType.Grid ? 'assets/img/btn_list_on.webp' : 'assets/img/btn_list_off.webp'
                  " />
                <img
                  [src]="search.boxType !== BoxType.Grid ? 'assets/img/btn_list_on.png' : 'assets/img/btn_list_off.png'"
                  style="width: 17px; height: 11px"
                  alt="Grid/List" />
              </picture>
            </button>
          </div>
          <div class="pl-2 py-1 py-lg-2">
            <div class="dropdown">
              <button
                class="dropdown-toggle pr-0"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                title="Ordenação">
                {{ orderName }}
                <i class="iconify" data-icon="mdi-sort"></i>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <button class="dropdown-item" (click)="onChangeOrder('destaque')">DESTAQUE</button>
                <button class="dropdown-item" (click)="onChangeOrder('recencia')">RECENTES</button>
                <button class="dropdown-item" (click)="onChangeOrder('menor-valor')">MENOR VALOR</button>
                <button class="dropdown-item" (click)="onChangeOrder('maior-valor')">MAIOR VALOR</button>
                <button class="dropdown-item" (click)="onChangeOrder('maior-area')">MAIOR ÁREA</button>
                <button class="dropdown-item" (click)="onChangeOrder('menor-area')">MENOR ÁREA</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Results-->
    <div
      class="row"
      [ngClass]="{
        'i-results-list py-5': search.boxType !== BoxType.Grid,
        'i-results-grid pt-5': search.boxType === BoxType.Grid,
      }">
      <div
        *ngFor="
          let property of resultPage?.items
            | paginate
              : {
                  id: 'pagination',
                  itemsPerPage: resultPage?.size,
                  currentPage: search.pag,
                  totalItems: resultPage?.total,
                }
        "
        class="i-box col-12"
        [ngClass]="{ 'col-md-6 col-lg-4 mb-5': search.boxType === BoxType.Grid }">
        <app-box [property]="property" [boxType]="search.boxType" [targetBlank]="true"></app-box>
      </div>
    </div>
    <!--Pagination-->
    <div class="i-pagination row justify-content-center flex-column pt-3 pb-5">
      <app-loading *ngIf="loading"></app-loading>
      <pagination-controls (pageChange)="onChangePage($event)" id="pagination" class="mx-auto" [directionLinks]="false">
      </pagination-controls>
    </div>
  </div>
</div>
<!-- Sugestão de nova busca ou encomenda -->
<app-search-order [search]="search"></app-search-order>
