<ng-container *ngIf="releases$ | async as releases">
  <ng-container *ngIf="releases?.length >= 1">
    <div style="min-height: 45rem">
      <div class="i-title row no-gutters justify-content-center text-center pb-3">LANÇAMENTOS DE IMÓVEIS</div>
      <div class="row row-cols-1 row-cols-md-4 py-2 no-gutters justify-content-center">
        <div *ngFor="let release of releases | slice: 0 : 4" class="col p-4">
          <app-summary [release]="release" [enableLogo]="true" [incorporadora]="true"></app-summary>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
