<div
  class="card"
  [ngClass]="hoverClass"
  (mouseenter)="hoverClass = 'shadown-lg'"
  (mouseout)="hoverClass = 'shadown-none'">
  <div style="position: relative">
    <img
      class="card-img-top"
      defaultImage="assets/img/img_loading.webp"
      [lazyLoad]="fotoDestaqueUrl"
      style="cursor: pointer"
      (click)="onSearch()"
      [alt]="empreendimento | uppercase" />
    <ng-container *ngIf="enableLogo && release?.logoIncorporadoraUrl">
      <a [routerLink]="['/incorporadora/', release?.incorporadoraSlug]" aria-label="Veja mais imóveis da incorporadora">
        <div class="i-logo-container">
          <img [src]="release?.logoIncorporadoraUrl" class="i-logo-incorp" [alt]="release?.incorporadoraNome" />
        </div>
      </a>
    </ng-container>
  </div>
  <!--Ribbon Lançamento / Incorporadora-->
  <div class="ribbon">
    <span [class]="release?.incorporadoraSlug ? 'i-incorp' : 'i-lancamento'">LANÇAMENTO</span>
  </div>
  <div class="card-body">
    <div class="i-subtitle">{{ empreendimento | uppercase }}</div>
    <div *ngIf="bairro" class="i-subtitle">Bairro {{ bairro }}</div>
    <div class="i-text py-2">
      <span [innerHTML]="areas"></span>
      <span *ngIf="vagas" class="dot">•</span>
      <span [innerHTML]="vagas"></span>
      <span *ngIf="quartos" class="dot">•</span>
      <span [innerHTML]="quartos"></span>
    </div>
    <div *ngIf="previsaoEntrega" class="i-text pt-1">Entrega {{ previsaoEntrega * 1000 | date: 'MMM yyyy' }}</div>
    <div class="i-subtitle pt-1" [innerHTML]="valores"></div>
    <div class="text-center px-4 py-3">
      <button type="button" class="i-btn col" (click)="onSearch()">
        VER {{ release?.qtdUnidades }} {{ 'unidade' | plural: release?.qtdUnidades | uppercase }}
      </button>
    </div>
  </div>
</div>
